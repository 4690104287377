
<template>
  <div>
    <el-form inline>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="addCoupons">新增折扣券</el-button>
      </el-form-item>
      <el-form-item label="标题">
        <el-input style="width:150px;" size="mini" placeholder="请输入卡券标题搜索" v-model="search"></el-input>
      </el-form-item>
      <el-form-item label="使用范围">
        <el-select v-model="province" placeholder="请选择使用范围" style="width:150px" @change="areaChange" size="mini">
          <el-option v-for="(item,index) in dataTree" :key="index" :label="item.regionName" :value="String(item.regionCode)"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市区" v-if="couponsType==1">
        <el-select v-model="city" placeholder="请选择使用范围" style="width:150px" size="mini">
          <el-option v-for="(item,index) in cityTree" :key="index" :label="item.regionName" :value="String(item.regionCode)"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table stripe :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column prop="vouchersNumber" align="center" label="卡券编码" width="140"></el-table-column>
      <el-table-column label="卡券标题" align="center">
        <template slot-scope="scope">
          <div class="goto-detail" @click="editDetail(scope.row.vouchersId, 1)">{{scope.row.vouchersTitle}}</div>
        </template>
      </el-table-column>
      <el-table-column label="供应商" align="center">
        <template slot-scope="scope">
          {{scope.row.supplierType==1?'鹤大师':scope.row.supplierType==0?'车多':''}}
        </template>
      </el-table-column>
      <el-table-column prop="regionName" align="center" label="使用范围"></el-table-column>
      <el-table-column prop="vouchersExplain" align="center" label="卡券说明"></el-table-column>
      <el-table-column prop="amountPrice" align="center" label="现金价格" width="120"></el-table-column>
      <el-table-column prop="integralPrice" align="center" label="积分价格" width="120"></el-table-column>
      <el-table-column prop="validityDay" align="center" label="有效天数" width="80"></el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" :type="!scope.row.isEnable ? 'primary' : 'warning'" disable-transitions>{{!scope.row.isEnable ?'启用':'禁用'}}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="createName" align="center" label="创建人"></el-table-column> -->
      <el-table-column prop="createTime" align="center" label="创建时间" width="150"></el-table-column>
      <el-table-column align="center" label="操作" width="260">
        <template slot-scope="scope">
          <!-- <el-button type="text" icon="el-icon-view" style="color:#333" @click="editDetail(scope.row.vouchersId,1)">查看</el-button> -->
          <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row.vouchersId,2)">编辑</el-button>
          <el-button type="text" icon="el-icon-edit" @click="addCoupons(scope.row)">复制</el-button>
          <el-button type="text" icon="el-icon-remove-outline" @click="setDisable(scope.row)">{{!scope.row.isEnable ? '禁用' : '启用'}}</el-button>
          <el-button type="text" icon="el-icon-delete" style="color:red" @click="delect(scope.row.vouchersId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top:10px;" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="pageSize" :current-page="pageNum" :total="total"></el-pagination>
    <el-dialog :destroy-on-close="true" :title="!editType ? '新增折扣券':editType == 1 ? '折扣券详情':'编辑折扣券'" :visible.sync="isAddDialog" v-if="isAddDialog" :lock-scroll="true" width="940px" :close-on-click-modal='false'>
      <Add v-if="isAddDialog" :isAddDialog.sync="isAddDialog" :editId.sync="editId" :editType.sync="editType" :copyItem="copyItem" :vouchersType="couponsType"></Add>
    </el-dialog>
  </div>
</template>
<script>
import {
  getVouchersList,
  delVouchers,
  getProvincesList,
  getRegionByRegionCode,
  isDisable
} from "@/api/setting";
import Add from "./components/add.vue";

export default {
  name: "cardCouponCoupons",
  components: {
    Add
  },
  data() {
    return {
      isAddDialog: false,
      editId: "",
      editType: "", // 编辑状态 1是详情，2是编辑,''是添加

      province: "",
      city: "",
      search: "", //搜索

      list: [],
      total: 0, //分页总页数
      pageNum: 1, //分页页数
      pageSize: 10, //每页显示多少
      couponsType: "", //页面类型，0：油券 1：洗保喷券
      dataTree: [],
      cityTree: [],
      copyItem: {}
    };
  },
  watch: {
    isAddDialog(n, o) {
      //关闭dialog后获取列表
      !n && this.getList();
    }
  },
  created() {
    this.getTreeList();
    this.getList();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    delect(id) {
      let that = this;

      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(res => {
        delVouchers(id).then(response => {
          if (response.code == 200) {
            that.list = that.list.filter(item => item.vouchersId !== id);
          }
          that.$message({
            type: response.code == 200 ? "success" : "default",
            duration: 2000,
            message: response.msg || response.error_msg
          });
        });
      });
    },
    getList() {
      let that = this;
      this.couponsType = this.$route.path == "/cardcoupon/wash" ? 1 : 0;
      let param = {
        isPage: 1,
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        vouchersType: this.couponsType,
        search: this.search
      };

      if (this.province) {
        let a = this.couponsType == 1 ? 'usedRegion' : 'usedArea'
        param[a] = this.province
      }
      if (this.city) {
        param.usedRegion = this.province
        param.usedArea = this.city
      }
      getVouchersList(param).then(response => {
        if (response.code == 200) {
          that.total = response.data.total;
          that.list = response.data.records;
        } else {
          that.$message.error({
            title: "错误",
            message: response.msg,
            duration: 1500
          });
        }
      });
    },
    getTreeList() {
      let that = this;
      getProvincesList().then(response => {
        if (response.code == 200) {
          that.dataTree = response.data;
        } else {
          that.$message.error({
            title: "错误",
            message: response.msg || response.error_msg,
            duration: 1500
          });
        }
      });
    },
    areaChange() {
      this.city = "";
      getRegionByRegionCode({ regionCode: this.province }).then(res => {
        this.cityTree = res.data;
      });
    },
    setDisable(item) {
      let isEnable = item.isEnable
      this.$confirm(isEnable == 1 ? '是否启用该卡券?' : '是否禁用该卡券?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          id: item.vouchersId,
          isEnable: isEnable ? 0 : 1
        }
        isDisable(param).then((response) => {
          if (response.code == 200) {
            this.getList();
          }
          this.$message({
            type: response.code == 200 ? 'success' : 'default',
            duration: 2000,
            message: response.msg || response.error_msg,
          });
        })
      }).catch(err => {

      });
    },
    // type == 1 查看详情 type == 2 编辑
    editDetail(id, type) {
      this.editType = type;
      this.editId = id;
      this.isAddDialog = true;
    },
    addCoupons(item) {
      this.editType = ''
      this.isAddDialog = true
      this.copyItem = { ...item, ...{ id: '', vouchersId: '', usedArea: '' } }
    }
  }
};
</script>