<template>
  <el-form @submit.native.prevent ref="form" :model="form" :rules="rules" label-width="125px">
    <el-row>
      <el-col :span="22">
        <el-form-item label="卡券图片">
          <uploadFileOnly :imageUrl.sync="form.vouchersPic" :isDel.sync="editShow" />
        </el-form-item>
      </el-col>
      <!-- <el-col :span="12">
      <el-form-item label="请选择券类型" >
        <el-radio-group v-model="form.vouchersType" :disabled="!editShow" >
          <el-radio :label="0" >抵扣券</el-radio>
          <el-radio :label="1" >洗车券</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-col> -->
      <el-col :span="11" v-show="form.vouchersType == 1">
        <el-form-item label="洗车券类型" prop="couponType">
          <el-select v-model="form.couponType" placeholder="请选择类型" style="width:250px;" :disabled="!editShow">
            <el-option v-for="(item,index) in washCarTypeArr" :key="index" :label="item.text" :value="item.index"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="供应商" prop="supplierType">
          <el-select v-model="form.supplierType" placeholder="请选择供应商" v-if="editShow" style="width:100%">
            <el-option v-for="(item,index) in supplierTypeList" :key="index" :label="item" :value="index"></el-option>
          </el-select>
          <div class="content-text" v-else>{{supplierTypeList[form.supplierType]}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="22">
        <el-form-item label="卡券标题" prop="vouchersTitle">
          <el-input style="width:100%;" placeholder="中英文、字母或数字组合,长度小于20位" v-model="form.vouchersTitle" maxlength="20" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.vouchersTitle}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="22">
        <el-form-item label="卡券说明" prop="vouchersExplain">
          <el-input style="width:100%;" placeholder="中英文、字母或数字组合，长度小于300字" type="textarea" v-model="form.vouchersExplain" maxlength="300" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.vouchersExplain}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="卡券编号" prop="vouchersNumber">
          <el-input placeholder="英文、字母或数字组合,长度小于16位" v-model="form.vouchersNumber" maxlength="16" v-if="editShow" :disabled="this.editType?true:false"></el-input>
          <div class="content-text" v-else>{{form.vouchersNumber}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="外部编号">
          <el-input placeholder="英文、字母或数字组合,长度小于16位" v-model="form.externalNumber" maxlength="16" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.externalNumber}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="显示顺序" prop="sort">
          <el-input style="width:100%;" placeholder="显示顺序" v-model="form.sort" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.sort}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="卡券价格" prop="vouchersPrice">
          <el-input placeholder="请输入金额（支持小数点后两位）" v-model="form.vouchersPrice" maxlength="11" oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.vouchersPrice}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11" v-if="form.vouchersType == 0">
        <el-form-item label="抵扣金额" prop="deductiblePrice">
          <el-input placeholder="请输入金额（支持小数点后两位）" v-model="form.deductiblePrice" maxlength="11" oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.deductiblePrice}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="现金价格" prop="amountPrice">
          <el-input placeholder="请输入金额（支持小数点后两位）" v-model="form.amountPrice" maxlength="11" oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.amountPrice}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="积分价格" prop="integralPrice">
          <el-input placeholder="请输入金额（支持小数点后两位）" v-model="form.integralPrice" maxlength="11" oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.integralPrice}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="有效期" prop="validityDay">
          <el-input placeholder="请输入天数（仅支持数字整数）" v-model.number="form.validityDay" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" v-if="editShow"></el-input>
          <div class="content-text" v-else>{{form.validityDay}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="使用范围" prop="usedArea">
          <el-select v-model="form.usedArea" placeholder="请选择使用范围" v-if="editShow" style="width:100%" @change="areaChange">
            <el-option v-for="(item,index) in dataTree" :key="index" :label="item.regionName" :value="String(item.regionCode)"></el-option>
          </el-select>
          <div class="content-text" v-else>{{usedArea}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="市区" v-if="form.vouchersType == 1">
          <el-select v-model="city" placeholder="请选择使用范围" v-if="editShow" style="width:100%">
            <el-option v-for="(item,index) in cityTree" :key="index" :label="item.regionName" :value="String(item.regionCode)"></el-option>
          </el-select>
          <div class="content-text" v-else>{{usedCity}}</div>
        </el-form-item>
      </el-col>
      <el-col :span="22">
        <el-form-item label="规则内容">
          <div id="div1" style="width:100%;height:330px;" v-if="editShow"></div>
          <div style="border:1px solid #eee;padding:10px;border-radius:10px;" class="content-text" v-else v-html="form.ruleContent"></div>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-button type="primary" v-debounce="onSubmit" v-if="!editType">立即创建</el-button>
        <el-button type="primary" v-debounce="onSubmit" v-if="editType == 2">立即保存</el-button>
        <el-button @click="dialogClose">关闭窗口</el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { getProvincesList } from '@/api/setting';
import { baseURL } from '@/utils/http';
import { addOrEditorVouchers, getVouchersInfo, getRegionByRegionCode, getRegionInfo } from '@/api/setting';
import uploadFileOnly from '@/components/uploadFileOnly'
import { getCookies } from '@/utils/utils'

import E from 'wangeditor'
var editor = null
export default {
  props: ['isAddDialog', 'editId', 'editType', 'vouchersType', 'copyItem'],
  components: {
    uploadFileOnly,

  },
  data() {
    var checkNumber = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/;
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确天数'));
      }
    };
    var checkMoney = (rule, value, callback) => {
      let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确金额'));
      }

      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入正确金额'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      washCarTypeArr: [
        {
          index: 4,
          text: '标准洗车',
        },
        {
          index: 5,
          text: '精致洗车',
        },
        // {
        //   index:6,
        //   text:'手工打蜡',
        // },
        // {
        //   index:7,
        //   text:'磨泥打蜡',
        // },
        {
          index: 8,
          text: '机油保养(全合成)',
        },
        {
          index: 19,
          text: '机油保养(半合成)',
        },
        {
          index: 20,
          text: '机油保养(矿物质)',
        },
        // {
        //   index:26,
        //   text:'单面喷漆(低档)',
        // },
        // {
        //   index:14,
        //   text:'单面喷漆(普通)',
        // },
        // {
        //   index:27,
        //   text:'单面喷漆(高档)',
        // },
        // {
        //   index:13,
        //   text:'钣金喷漆',
        // },
        // {
        //   index:22,
        //   text:'镀晶',
        // },
        // {
        //   index:23,
        //   text:'镀膜',
        // }
      ],
      supplierTypeList: ['车多', '鹤大师'],
      form: {
        vouchersType: 0,                   //券类型
        couponType: '',                    // 洗车券类型
        createBy: getCookies('sysId'),    //创建人id
        actualAmount: '',                 //实际金额
        deductiblePrice: '',              //抵扣金额
        vouchersPrice: '',                 //销售价格
        vouchersTitle: '',                //卡券标题
        vouchersExplain: '',              //卡券说明
        vouchersNumber: '',               //卡券编号
        vouchersPic: '',                  //卡券图片
        validityDay: '',                   //有效期
        ruleContent: '',                  //规则内容
        usedArea: '',                     //使用范围
        sort: '',      //显示顺序
        supplierType: '',//供应商
        amountPrice: '',//金额价格
        integralPrice: '',//积分价格
        externalNumber: ''
      },
      rules: {
        vouchersPrice: [
          { required: true, message: '销售金额不可为空', trigger: 'blur' },
          { validator: checkMoney, trigger: 'blur' }
        ],
        vouchersTitle: [
          { required: true, message: '卡券标题不可为空', trigger: 'blur' },
        ],
        vouchersExplain: [
          { required: true, message: '卡券说明不可为空', trigger: 'blur' },
        ],
        vouchersNumber: [
          { required: true, message: '卡券编号不可为空', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '显示顺序不可为空', trigger: 'blur' },
        ],
        validityDay: [
          { required: true, message: '有效期不可为空', trigger: 'change' },
          { validator: checkNumber, trigger: 'blur' }
        ],
        usedArea: [
          { required: true, message: '使用范围不能为空', trigger: 'change' },
        ],
        supplierType: [
          { required: true, message: '请选择供应商', trigger: ['blur', 'change'] },
        ],//供应商
        amountPrice: [
          { required: true, message: '现金价格不可为空', trigger: 'blur' },
        ],//金额价格
        integralPrice: [
          { required: true, message: '积分价格不可为空', trigger: 'blur' },
        ],//积分价格

      },
      dataTree: [],
      cityTree: [],
      city: ''

    }
  },
  watch: {
    isAddDialog(n, o) {
      return
      if (!n) {
        this.form = {
          couponType: '',
          createBy: getCookies('sysId'),    //创建人id
          actualAmount: '',                 //实际金额
          deductiblePrice: '',              //抵扣金额
          vouchersPrice: '',                 //销售价格
          vouchersTitle: '',                //卡券标题
          vouchersExplain: '',              //卡券说明
          vouchersNumber: '',               //卡券编号
          vouchersPic: '',                  //卡券图片
          validityDay: '',                   //有效期
          ruleContent: '',                  //规则内容
        };
        // 销毁编辑器
        editor.destroy()
        editor = null
      }
    }
  },
  computed: {
    editShow() {
      return this.editType == 2 || !this.editType
    },
    usedArea() {
      let item = this.dataTree.find(item => {
        return item.regionCode == this.form.usedArea
      })
      if (item && item.regionName) {
        return item.regionName
      } else {
        return '全国'
      }
    },
    usedCity() {
      let item = this.cityTree.find(item => {
        return item.regionCode == this.city
      })
      if (item && item.regionName) {
        return item.regionName
      } else {
        return '全省'
      }
    }
  },
  created() {
    this.form.vouchersType = this.vouchersType
    this.editType && this.editId && this.getDateil();
    Object.assign(this.form, this.copyItem)
    console.log(this.form)
    this.getTreeList()
  },
  mounted() {
    if (this.editShow || this.copyItem) {
      editor = new E('#div1');
      editor.config.height = 250;
      editor.config.uploadImgServer = baseURL + '/uploadFile/uploadFileTwo';
      editor.config.uploadFileName = 'mfile'

      let headers = {
        'x-token': getCookies('x-token')
      }
      editor.config.uploadImgHeaders = headers;

      editor.config.uploadImgMaxLength = 1
      editor.create();
      editor.txt.html(this.form.ruleContent)
      console.log(this.form.ruleContent)
    }
  },
  methods: {
    changeCheck(checkKey) {
      // 去中文正则
      this.form[checkKey] = this.form[checkKey].replace(/[^\w\.\/]/ig, '');
    },
    getDateil() {
      getVouchersInfo({ 'vouchersId': this.editId })
        .then((response) => {
          if (response.code == 200) {
            this.form = response.data;
            if (this.editShow) {
              editor.txt.html(this.form.ruleContent)
            }
            this.form['couponType'] = Number(response.data['couponType']);
            if (this.vouchersType == 1) {
              if (this.form.usedArea.includes('0000')) {
                this.areaChange()
                return
              }
              getRegionInfo({ regionCode: this.form.usedArea }).then(res => {
                this.form.usedArea = String(res.data.parentRegionCode)
                this.areaChange()
                this.city = String(res.data.regionCode)
              })
            }
          } else {
            this.$message.error({
              title: '错误',
              message: response.msg,
              duration: 1500
            });
          }

        })
    },
    dialogClose() {
      this.$emit('update:isAddDialog', false)
    },
    getTreeList() {
      let that = this;
      getProvincesList()
        .then((response) => {
          if (response.code == 200) {
            that.dataTree = response.data;
          } else {
            that.$message.error({
              title: '错误',
              message: response.msg || response.error_msg,
              duration: 1500
            });
          }
        })
    },
    areaChange() {
      this.city = ''
      getRegionByRegionCode({ regionCode: this.form.usedArea }).then(res => {
        this.cityTree = res.data
      })
    },
    onSubmit() {
      var that = this;
      let form = JSON.parse(JSON.stringify(that.form));
      form.ruleContent = editor.txt.html();
      if (this.vouchersType == 1) {
        if (!this.city) {
          this.$message.warning('请选择市区')
          return
        }
        if (this.city) {
          form.usedArea = this.city
        }
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          addOrEditorVouchers(form)
            .then((response) => {
              if (response.code == 200) {
                that.dialogClose();
                that.$message({
                  message: response.msg,
                  type: 'success'
                });
              } else {
                that.$message.error({
                  title: '错误',
                  message: response.msg,
                  duration: 1500
                });
              }
            })
        } else {
          this.$message.error({
            title: '错误',
            message: '请检查完善输入项并重试',
            duration: 1500
          });
          return false;
        }
      })
    }
  }
}
</script>
<style scoped>
.el-form-item {
  text-align: left;
}
.content-text {
  letter-spacing: 1px;
  color: #333333;
  margin: 0px 10px;
  font-size: 16px;
}
</style>